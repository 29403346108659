import  {useEffect, useState} from "react";
import {DocComponentProps} from '../../../type/props';
import {clone,has ,isEmpty} from 'structkit';

let boolReady = 0;
let searchEvent:any = null;
function ApiBodyMenu(project:DocComponentProps){

	const {projects} = project;
	let cloneProject:any = clone(projects);
	const [valueList, setValueList]= useState<any[]>([]);

	const searchProjectOnChange = (event:any)=>{

		if (isEmpty(event.target.value)){
			cloneProject =projects;
		}
		clearInterval(searchEvent);	
		cloneProject = cloneProject.filter( (val:any)=>{
			if (has(val)) {
				var regexp = new RegExp(""+event.target.value.toLowerCase()+"","g")
				
				return regexp.test(val.name.toLowerCase());
			}
			
		});
		
		searchEvent = setInterval(()=>{
		 
			setValueList(cloneProject);
	 
		},200)
	}
	
	
	useEffect(()=>{
		
		setValueList(cloneProject);
			
		
	},[project]);

	return (
			<> 
				<div className="structkit_content_list_option">
					<div className="projec_search_box"> 
							<input className="fields" onChange={searchProjectOnChange} placeholder="Search"/>
				 	 
						
					</div>
					<ul className="list-project-content">
					<>

						{
							valueList.map((option:any) =>(
								<li >
									<a href={'#'+option.name}>{option.name}</a>
								</li>
							))
						}
						</>
					</ul>
				</div>
			
			</>
		);
}
export default ApiBodyMenu;
