
import {TheBodyProps} from '../../../type/props';
import {imageLoad} from "../../../utils/element";
import PageFooter from "../PageFooter";
import HeaderLink from "../HeaderLink";
import {getHostDetails} from 'url-assist';
import {ifUndefined, isEmpty} from 'structkit';
import {projectDetails} from '../../../constants/RoutesDomain';
import { useRef } from 'react';
import {dom} from 'compts-js';

//project:HeaderSubDomainProps,
function TheBody(project:TheBodyProps){

    console.log(project.children,":element");
    
	const domainDetails = getHostDetails(window.location.origin)
    const {isWithSideBody, children} = project;

	let strImage:string = domainDetails['domainDetails']['subdomain']+".png"
    const detailsProject = ifUndefined(projectDetails,domainDetails['domainDetails']['subdomain'],{
		github:"",
		package:""
	});

    const inputRef = useRef(null);

    const menu_event = ()=>{
		//dom(".mobile").css({"display":"block"});
		dom(inputRef.current).findElem("ul").toggleDisplay("block");
	}
	//
    return <>
				 
					{isWithSideBody ? (
						<>
						 	<div className="structkit_content_inline container-xl">
								 {children}
							</div>
						</>	
					):(
						<>
						 	{children}
						</>
						
						
					)}
					
					
					
			 
               
           
        </>
}
export default TheBody;
