import { connect } from "react-redux";
import {getListOfProject} from '../../data/repository/graphql';

const mapStateToProps = (state:any) =>{

	return {
		getListOfProjectReduce:state.getListOfProjectReducer
	}
}
const mapDispathToProps ={
	getListOfProject,
}
const StatesExtend = connect(mapStateToProps,mapDispathToProps);
export default StatesExtend;
