import {Component} from "react";
import TheBody from 'app/component/design/TheBody';
import TheHead from 'app/component/design/TheHead';
import PageFooter from 'app/component/PageFooter';

import {getkitVersion} from '../../../../constants/projectVersion';

import ApiBody from 'app/component/design/ApiBody';
import ApiBodyMenu from 'app/component/design/ApiBodyMenu';

//projects
class Api extends Component<{}> {
	
	constructor(){
		super({});
		this.state ={
			defaultSelectValue:getkitVersion[0].value
		}
	}
	async getData(){
		const main: any = this;
		main.props.getListOfProject(main.state.defaultSelectValue);

	}
	componentWillMount(){
		this.getData();
	}
	componentDidMount(){
		document.title = "Fornetserve JS document page"
	}
	render(){
		const main: any = this;
		const dropdownVersion=(event:any) =>{
			
			this.setState({
				defaultSelectValue:event.target.value
			},()=>{
				main.getData();
			});	
		}
		return (
			<>
			<TheHead>
			<ApiBodyMenu projects={main.props.getListOfProjectReduce}/>
			</TheHead>
			<TheBody isWithSideBody={true}>
		 
				
				<ApiBody projects={main.props.getListOfProjectReduce}/>
				<div className="container cols-12">
				<h2>config at request</h2>
				<p></p>
			  	<table className="table">
			  			<thead>
			  				<th>Method name</th>
							<th>Description</th>
							<th>Example</th>  
						</thead>
						<tbody>
			  				<tr>
								  <td>data</td>
								  <td>Request data</td>
								  <td>&lbrace;"test":1&rbrace;</td>
							</tr>
							<tr>
								  <td>header</td>
								  <td>Request header</td>
								  <td>&lbrace;"content-type":"application/json"&rbrace;</td>
							</tr>
							<tr>
								  <td>isJson</td>
								  <td>Request return data must be json</td>
								  <td>&lbrace;"isJson":true&rbrace;</td>
							</tr>
							<tr>
								  <td>widthCredentials</td>
								  <td>Request allow session of cookies to be kept from backend server</td>
								  <td>&lbrace;"widthCredentials":true&rbrace;</td>
							</tr>
						</tbody>
			  	</table>
			</div>
			 
			</TheBody>	
			<PageFooter/>			
			</>
		)
	}
}
export default Api;
