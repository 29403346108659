
export const getMaindomain =():string=>{
    const baseUrl = window.location.host.split(".").splice(1).join(".");

    return baseUrl;
}

export const imageLoad =(src:string):string=>{
    const baseUrl = getMaindomain();

    return `${window.location.protocol}//apis-img.${baseUrl}/${src}`
}

export const jsLoad =(src:string):string=>{
    const baseUrl = getMaindomain();

    return `${window.location.protocol}//apil.${baseUrl}/script/${src}`
}

export const mainHost =():string=>{
    const baseUrl = getMaindomain();

    return `${window.location.protocol}//${baseUrl}`
}
