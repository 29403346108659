import { Component } from "react";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

import { getkitVersion } from "../../../../constants/projectVersion";

//projects
class Docs extends Component<{}> {
  constructor() {
    super({});

    this.state = {
      defaultSelectValue: getkitVersion[0].value,
    };
  }
  async getData() {
    const main: any = this;
    main.props.getListOfProject(main.state.defaultSelectValue);
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    document.title = "Pluton home page";
  }
  render() {
    const main: any = this;
    const dropdownVersion = (event: any) => {
      this.setState(
        {
          defaultSelectValue: event.target.value,
        },
        () => {
          main.getData();
        }
      );
    };
    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody>
            <h3>Status</h3>
            <div className="cards">
              <div className="cards-body">
                Currently, I am working for version release <i>0.0.1-alpha</i>
              </div>
            </div>
            <h3>What is lobby</h3>
            <div className="cards">
              <div className="cards-body">
                These are list of framework that are Currently been tested for
                python development. you can find it at{" "}
                <a href="https://github.com/fonipts/pluton-lobby">
                  Github lobby
                </a>
              </div>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Docs;
