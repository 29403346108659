import {Component} from "react";
import HtmlScript from 'app/component/HtmlScript';
import TheBody from 'app/component/design/TheBody';
import TheHead from 'app/component/design/TheHead';
import PageFooter from 'app/component/PageFooter';


class Home extends Component<{}> {
	
	async getData(){

	}
	componentWillMount(){}
	componentDidMount(){

		document.title = "Dezainiwa JS home page"
	}
	render(){

		return (
			<>
			<TheHead/>
			<TheBody>
			<div className="container-lg align-center"> 

				<h3>Installation</h3>
				<div className="cards">
				<div className="cards-header">Browser</div>
					<h4>Download Dependency</h4>
					<a href="https://raw.githubusercontent.com/compts/structkit/main/dist/web/web/structkit-full.iife.js">Download Structkit Dependency</a>
					<a href="https://raw.githubusercontent.com/compts/compts-js/blob/upgrade/dev-2.0.1/dist/web/compts-full.js">Download comptsjs Dependency</a>
					<a href="https://raw.githubusercontent.com/dezainiwa/dezainiwa-web/feature/code-restructure-dev-2.0.0-pre/dist/js/dezainiwa-web.js">Download JS copy</a>
					<a href="https://raw.githubusercontent.com/dezainiwa/dezainiwa-web/feature/code-restructure-dev-2.0.0-pre/dist/css/dezainiwa-web.css">Download CSS copy</a>
					<h4>Script</h4>
					<code>
						<HtmlScript src="structkit-full.iife.js"/><br/>
						<HtmlScript src="compts-full.js"/><br/>
						<HtmlScript src="dezainiwa-web.js"/>
					</code>
				</div>
				<div className="cards">
				<div className="cards-header">NodeJs</div>
					<code>
						npm i comptsjs
					</code>
				</div>

				<h3>Code</h3>
				<div className="cards">
				<div className="cards-header">Browser</div>
					 
					<code>
						_stk.repeat("@@@",2)
					</code>
				</div>
				<div className="cards">
				<div className="cards-header">NodeJs</div>
					<code>
						import _stk from 'structkit';<br/>
						_stk.repeat("@@@",2)
					</code>
				</div>
			</div>
			</TheBody>	
			<PageFooter/>		
			</>
		)
	}
}
export default Home;
