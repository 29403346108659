import { Component } from "react";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

class Home extends Component<{}> {
  async getData() {}
  componentWillMount() {}
  componentDidMount() {
    document.title = "Grasseum JS document page";
  }
  render() {
    return (
      <>
        <TheHead />
        <div className="container align-center">
          <TheBody>
            <h3>Status</h3>
            <div className="cards">
              <div className="cards-body">Working in progress</div>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Home;
