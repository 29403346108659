import {Component} from "react";
import {urlassistVersion} from '../../../../constants/projectVersion';

import ApiBody from 'app/component/design/ApiBody';
import ApiBodyMenu from 'app/component/design/ApiBodyMenu';
import {tableDetails} from 'type/constant';
import TheBody from 'app/component/design/TheBody';
import TheHead from 'app/component/design/TheHead';
import PageFooter from 'app/component/PageFooter';

//projects
class Api extends Component<{}> {
	
	constructor(){
		super({});
		
		this.state ={
			defaultSelectValue:urlassistVersion[0].value
		}
	}

	async getData(){
		const main: any = this;

		main.props.getListOfProject("urlassist",main.state.defaultSelectValue);

	}
	componentWillMount(){

		this.getData();
	}
	componentDidMount(){
		document.title = "Url-assist JS document page"
	}

	
	render(){
		const main: any = this;
		const dropdownVersion=(event:any) =>{
			
			this.setState({
				defaultSelectValue:event.target.value
			},()=>{
				main.getData();
			});
			
		}
		const dataTable:tableDetails[] = [
			{
				name: 'urlComposer',
				thead: ['Method', 'Desription', 'Example'],
				tbody: [
					{
						value:['setProtocol', 'set prefered protocol','data.setProtocol("https")']
					},
					{
						value:['setPort ', 'set prefered port','data.setPort ("4040")']
					},
					{
						value:['setPath ', 'set prefered path','data.setPath ("v1/test")']
					},
					{
						value:['setDomain ', 'set prefered domain','data.setDomain ("example")']
					},
					{
						value:['setDomainTld ', 'set prefered tld','data.setDomainTld ("com")']
					},
					{
						value:['setDomainSubdomain ', 'set prefered subdomain','data.setDomainSubdomain ("api")']
					}
					,
					{
						value:['getToString', 'get your domain preferred format','data.getToString()']
					}
				]
			},
			{
				name: 'urlPattern',
				thead: ['Method', 'Desription', 'Example'],
				tbody: [
					{
						value:['isValid', 'check if pattern is match in path','data.isValid()']
					},
					{
						value:['getParam  ', 'get the parameter base on regexp group you specify','data.getParam()']
					}
				]
			}
		];
		return (
			<>
				<TheHead>
				<div className="select_version_ui">
					<select className = "dropdown" onChange={dropdownVersion}>
						{ urlassistVersion.map(opt => <option value={opt.value}>{opt.name}</option>)}
					</select>									
				</div>
				<ApiBodyMenu projects={main.props.getListOfProjectReduce} jsfile="urlassist.js" globalname="urs"/>
				</TheHead>

			<TheBody isWithSideBody={true}>
			
		
				
				<ApiBody projects={main.props.getListOfProjectReduce} isCodeRun={true} jsfile="urlassist.js" globalname="urs" table={dataTable} />
		 
			</TheBody>
			<PageFooter/>
			</>
		)
	}
}

export default Api;
