import { Component } from "react";
import { getkitVersion } from "../../../../constants/projectVersion";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

import ApiBody from "app/component/design/ApiBody";
import ApiBodyMenu from "app/component/design/ApiBodyMenu";

//projects
class Api extends Component<{}> {
  constructor() {
    super({});
    this.state = {
      defaultSelectValue: getkitVersion[0].value,
    };
  }
  async getData() {
    const main: any = this;
    main.props.getListOfProject(main.state.defaultSelectValue);
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    document.title = "Pluton home page";
  }
  render() {
    const main: any = this;
    const dropdownVersion = (event: any) => {
      this.setState(
        {
          defaultSelectValue: event.target.value,
        },
        () => {
          main.getData();
        }
      );
    };
    return (
      <>
        <TheHead />
        <div className="container align-center">
          <TheBody>
            <h3>Introduction</h3>
            <div className="cards">
              <div className="cards-body">Working in progress</div>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Api;
