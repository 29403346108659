import React from "react";
import {BrowserRouter as Router, Switch,Route} from "react-router-dom";
import routeGrasseum from 'app/domain/grasseum';
import routeCompts from 'app/domain/compts';
import routeStructkit from 'app/domain/structkit';
import routeDezainiwa from 'app/domain/dezainiwa';
import routeGetkit from 'app/domain/getkit';
import routeUrlassist from 'app/domain/urlassist';
import routeFornetserve from 'app/domain/fornetserve';
import routeCastanett from 'app/domain/castanett';
import routePlutonkit from 'app/domain/plutonkit';
import routeCloverpage from 'app/domain/cloverpage';

import {Home as pageerrorHome} from 'app/domain/pageerror/container';

let mapRoute = new Map<string, any>()  

mapRoute.set(routeGrasseum.SubDomain , routeGrasseum.SubRoute)
mapRoute.set(routeCompts.SubDomain , routeCompts.SubRoute)
mapRoute.set(routeStructkit.SubDomain , routeStructkit.SubRoute)
mapRoute.set(routeDezainiwa.SubDomain , routeDezainiwa.SubRoute)
mapRoute.set(routeGetkit.SubDomain , routeGetkit.SubRoute)
mapRoute.set(routeUrlassist.SubDomain , routeUrlassist.SubRoute)
mapRoute.set(routeFornetserve.SubDomain , routeFornetserve.SubRoute)
mapRoute.set(routeCastanett.SubDomain , routeCastanett.SubRoute)
mapRoute.set(routePlutonkit.SubDomain , routePlutonkit.SubRoute)
mapRoute.set(routeCloverpage.SubDomain , routeCloverpage.SubRoute)

export default function App(){
  const subdomain = window.location.host.split(".")[0];

  if (mapRoute.get(subdomain)) {
    return mapRoute.get(subdomain)();
  }
  return (
    <Router>
      <Switch>
        <Route path="/" component ={pageerrorHome} />
      </Switch>
    </Router>
  )
}
