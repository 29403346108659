import { Component } from "react";
import { getkitVersion } from "../../../../constants/projectVersion";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

//projects
class Docs extends Component<{}> {
  constructor() {
    super({});

    this.state = {
      defaultSelectValue: getkitVersion[0].value,
    };
  }
  async getData() {
    const main: any = this;
    main.props.getListOfProject(main.state.defaultSelectValue);
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    document.title = "Cloverpage home";
  }
  render() {
    const main: any = this;
    const dropdownVersion = (event: any) => {
      this.setState(
        {
          defaultSelectValue: event.target.value,
        },
        () => {
          main.getData();
        }
      );
    };
    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody>
            <h3>Status</h3>
            <div className="cards">
              <div className="cards-body">
                Currently, I am working for version release <i>0.0.1-alpha</i>
              </div>
            </div>
            <h3>How to configure</h3>
            <div className="cards">
              <div className="cards-body">
                First you need to create config file in your project directory
                cloverrc.yaml and copy what we had in this repository.
                <br />
                Then run this command at your terminal.
              </div>
              <code>
                cloverpage <i>lint</i>
              </code>
            </div>
            <h3>Available command you can use at your terminal</h3>
            <div className="cards">
              <div className="cards-body">
                The commands must in this format{" "}
                <i>{"cloverpage <Command type>"}</i>
              </div>
              <table className="table">
                <thead>
                  <th>Command type</th>
                  <th>Description</th>
                  <th>Example</th>
                </thead>
                <tbody>
                  <tr>
                    <td>format</td>
                    <td>Format your project </td>
                    <td>
                      <i>cloverpage format</i>
                    </td>
                  </tr>
                  <tr>
                    <td>lint</td>
                    <td>Lint your project </td>
                    <td>
                      <i>cloverpage lint</i>
                    </td>
                  </tr>
                  <tr>
                    <td>help</td>
                    <td>See available command for cloverpage </td>
                    <td>
                      <i>cloverpage help</i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Docs;
