import { Component } from "react";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

class Home extends Component<{}> {
  async getData() {}
  componentWillMount() {}
  componentDidMount() {
    document.title = "Pluton home page";
  }
  render() {
    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody>
            <h3>What is PlutonKit</h3>
            <div className="cards">
              <div className="cards-body">
                PlutonKit is a project management tool, that you can create and
                modify your project base on your selected architecture from the
                start.
              </div>
              <img
              src="image/project/pluton-kit-terminal-design.gif"
              alt={"pluton-kit-terminal-design"}
              width="50%"
            />
            </div>
            <h3>Introduction</h3>
            <div className="cards">
              <div className="cards-body">
                Building from scratch is quite a dauting task. Constructing your
                thought, looking for feature and research it will take alot of
                your time in figuring what will you do next. Therefore I decided
                to create application where you can choose in different
                framework, either zero or hero it will help you alot visualize
                what framework will you choose.
              </div>
            </div>
            <h3>Installation</h3>
            <div className="cards">
              <div className="cards-body">In your local machine</div>
              <code>pip install -e .</code>
              <div className="cards-body">In using Pip install</div>
              <code>pip install plutonkit</code>
            </div>
            <h3>Why we need another project management</h3>
            <div className="cards">
              <div className="cards-body">
                There are several template generator that is available public
                repository, but they lack of user control in favored of there
                likes.
                <ul>
                  <li>
                    to have condition, for feature that you want and available
                    in architecture.
                  </li>
                  <li>Custom template that makes this project unique.</li>
                </ul>
              </div>
            </div>
            <h3>Roadmap</h3>
            <div className="cards">
              <div className="cards-body">
                Currently we are in alpha phase had not reach 100% test coverage
                and some linter(due to feature I am currently in focused) but
                still committed to deliver the improvement of the tool.
              </div>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Home;
