import { Component } from "react";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";
import { comptsVersion } from "../../../../constants/projectVersion";

//projects
class Docs extends Component<{}> {
  constructor() {
    super({});

    this.state = {
      defaultSelectValue: comptsVersion[0].value,
    };
  }

  async getData() {
    const main: any = this;

    main.props.getListOfProject("compts", main.state.defaultSelectValue);
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    document.title = "Compts JS document page";
  }

  render() {
    const main: any = this;
    const dropdownVersion = (event: any) => {
      this.setState(
        {
          defaultSelectValue: event.target.value,
        },
        () => {
          main.getData();
        }
      );
    };

    return (
      <>
        <TheHead />
        <div className="container align-center">
          <TheBody>
            <h3>Status</h3>
            <div className="cards">
              <div className="cards-body">Working in progress, as I am working on this library to support framework like React,Vue and Angular JS</div>
            </div>
            <h3>Using in ReactJS</h3>
            <div className="cards">
              <div className="cards-body">
                This is trial feature on react, as this framework uses virtual
                dom therefore you need to import <b>useRef</b>
              </div>
              <code>
                import {"{ useRef }"} from 'react';
                <br />
                import {"{ dom }"} from 'comptsjs';
                <br />
              </code>
              <div className="cards-body">
                here is sample code for you to use.
              </div>
              <code>
                {"function Example(){"}
                <br />
                &nbsp;{"const inputRef = useRef(null);"}
                <br />
                &nbsp;{"dom(inputRef.current).toggleDisplay();"}
                <br />
                &nbsp;{""}
                <br />
                &nbsp;{"return <div ref={inputRef}>Hello world</div>"}
                <br />
                {""}
                <br />
                {"}"}
                <br />
                {"export default Example;"}
                <br />
              </code>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Docs;
