
import {HeaderSubDomainProps} from '../../../type/props';
import { Link ,useHistory  } from "react-router-dom";

import {imageLoad} from "../../../utils/element";
import PageFooter from "../PageFooter";
import HeaderLink from "../HeaderLink";
import {getHostDetails} from 'url-assist';
import {ifUndefined, isEmpty,stringCapitalize } from 'structkit';
import {projectDetails} from '../../../constants/RoutesDomain';
import { useRef,useState } from 'react';
import {dom} from 'compts-js';

//project:HeaderSubDomainProps,
function TheHead(project:HeaderSubDomainProps){

   	const [isMobileShow,setMobileShow] = useState(false);
    
	const [menuIcon,setMenuIcon] = useState("menu.png");
    
	const domainDetails = getHostDetails(window.location.origin)

	let strDomainName:string = domainDetails['domainDetails']['subdomain'];
	let strImage:string = strDomainName+".png"
    const detailsProject = ifUndefined(projectDetails,domainDetails['domainDetails']['subdomain'],{
		github:"",
		package:""
	});

    const inputRef = useRef(null);

    const menu_event = ()=>{
		//dom(".mobile").css({"display":"block"});
		//dom(inputRef.current).findElem("ul").toggleDisplay("block");
		setMobileShow(!isMobileShow);
		setMenuIcon(isMobileShow?"menu.png":"menu_open.png");
	}
    return <>
				<div>
				<header >
				<div className="container-fluid">
					<div className='align-right header-right-group'>
							<a href={detailsProject.github} className="link">
							<img src={imageLoad("github-icon.png")} alt={"logo"} width="40px"/>
                            </a>
							{
								!isEmpty(detailsProject.package)?<>
									<a href={detailsProject.package} className="link">
							<img src={imageLoad("install-icon.png")} alt={"logo"} width="40px"/>
                            </a>
								</>:<></>
							}
					</div>
					<div className="rows header-left-group">
						<div
							className="align-text-xs-center align-text-sm-center align-text-left"
						>
							<ul
							className="list-group"
						>		

								<li className="list-items list-inline">

									<div className="mobile_menu_icon" onClick={menu_event}>
										<img src={imageLoad(menuIcon)} alt={"menu"} width="50px"/>  
									</div>
								</li>
								<li className="list-items list-inline">

									<a href="/" className="link">
										<img src={imageLoad(strImage)} alt={"logo"} width="60px"/> <span className='domain_name'>{stringCapitalize(strDomainName)}</span>
									</a>
									
								</li>
								<li className="list-items list-inline">
									<div className='desktop_display'>
										<HeaderLink github='' download=''/>
									</div>
								</li>	
							</ul>
						</div>
					</div>
				</div>
			</header>
				</div>
				
			<div className='mobile_display' >
				{isMobileShow?(
					<>
						<div className='mobile_menu'>
							<HeaderLink github='' download=''/>
							{
								project.children
							}
						</div>
					</>
				):(
					<></>
				)}
				
			</div>
			{project.children ? (
				<>
					<div className='structkit_content_inline' >
					<div className='desktop_display' >
						{
							project.children
						}
						</div>
					</div>
				</>
			) : (
				<></>
			)}
               
           
        </>
}
export default TheHead;
