import { connect } from "react-redux";

const mapStateToProps = (state:any) =>{

	return {
		
	}
}
const mapDispathToProps ={

}
const StatesExtend = connect(mapStateToProps,mapDispathToProps);
export default StatesExtend;
