import { Component } from "react";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

class Home extends Component<{}> {
  async getData() {}
  componentWillMount() {}
  componentDidMount() {
    document.title = "Cloverpage home";
  }
  render() {
    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody>
            <h3>What is Cloverpage</h3>
            <div className="cards">
              <div className="cards-body">
                Cloverpage is a static code analyzer that supports different
                language at your choice
              </div>
            </div>
            <h3>Introduction</h3>
            <div className="cards">
              <div className="cards-body">
                The intention of this tool is to scan the code, API traffic and
                Performance of the Web. To have a synchronization tool across
                Different language and platform, that easily maintain by
                Enthusiast at heart.
                <br />
                <br />
                <i>Cloverpage</i> provide a continues code inspection for
                quality code, api performance in your application.
              </div>
            </div>
            <h3>Installation</h3>
            <div className="cards">
              <div className="cards-body">
                Currently, we do not have an instruction to install in your
                Respective machine, but please take a look in our github
                Repository.
              </div>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Home;
