import { Component } from "react";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";
import { getkitVersion } from "../../../../constants/projectVersion";

//projects
class Docs extends Component<{}> {
  constructor() {
    super({});

    this.state = {
      defaultSelectValue: getkitVersion[0].value,
    };
  }

  async getData() {
    const main: any = this;

    main.props.getListOfProject("getkit", main.state.defaultSelectValue);
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    document.title = "Getkit JS document page";
  }

  render() {
    const main: any = this;
    const dropdownVersion = (event: any) => {
      this.setState(
        {
          defaultSelectValue: event.target.value,
        },
        () => {
          main.getData();
        }
      );
    };
    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody>
            <div className="cards">
              <div className="cards-body">Working in progress</div>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Docs;
