import React from "react";

function PageHeader(){
	return (
		<>
			<div>
				Header
			</div>
		</>
	)
}
export default PageHeader;
