import { Component } from "react";
import HtmlScript from "app/component/HtmlScript";

import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

class Home extends Component<{}> {
  async getData() {}
  componentWillMount() {}
  componentDidMount() {
    document.title = "Url-assist JS home page";
  }
  render() {
    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody>
            <h3>Introduction</h3>
            <div className="cards">
              <div className="cards-body">URL library at your ease</div>
            </div>
            <h3>Installation</h3>
            <div className="cards">
              <div className="cards-header">Browser</div>
              <h4>Download Dependency</h4>
              <a href="https://raw.githubusercontent.com/compts/structkit/main/dist/web/web/structkit-full.iife.js">
                Download Structkit Dependency
              </a>
              <a href="https://raw.githubusercontent.com/compts/url-assist/blob/main/dist/web/url-assist.js">
                Download copy
              </a>
              <h4>Script</h4>
              <code>
                <HtmlScript src="structkit-full.iife.js" />
                <br />
                <HtmlScript src="url-assist.js" />
              </code>
            </div>
            <div className="cards">
              <div className="cards-header">NodeJs</div>
              <code>npm i url-assist</code>
            </div>

            <h3>Code</h3>
            <div className="cards">
              <div className="cards-header">Browser</div>
              <div className="cards-body">
                keep in mind <b>urs</b> is used as global variable at html.
              </div>
              <code>urs.isHttps('https://example.com') {`=>`} true</code>
            </div>
            <div className="cards">
              <div className="cards-header">NodeJs</div>
              <code>
                import {`{isHttps}`} from 'url-assist';
                <br />
                isHttps("https://example.com") {`=>`} true
              </code>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Home;
