import {Component} from "react";
import {getkitVersion} from '../../../../constants/projectVersion';

import TheBody from 'app/component/design/TheBody';
import TheHead from 'app/component/design/TheHead';
import PageFooter from 'app/component/PageFooter';

//projects
class Docs extends Component<{}> {
	
	constructor(){
		super({});
		
		this.state ={
			defaultSelectValue:getkitVersion[0].value
		}
	}
	async getData(){
		const main: any = this;
		main.props.getListOfProject(main.state.defaultSelectValue);
	}
	componentWillMount(){
		this.getData();
	}
	componentDidMount(){
		document.title = "Fornetserve JS document page"
	}
	render(){
		const main: any = this;
		const dropdownVersion=(event:any) =>{
			this.setState({
				defaultSelectValue:event.target.value
			},()=>{
				main.getData();
			});
		}
		return (
			<>
			<TheHead/>
			<TheBody >
				<div className="container align-center">
					Working in progress	
				</div>
							
			</TheBody>	
			<PageFooter/>			
			</>
		)
	}
}
export default Docs;
