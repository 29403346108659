import { Component } from "react";

import { connect } from "react-redux";

import { structkitVersion } from "../../../../constants/projectVersion";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

//projects
class Docs extends Component<{}> {
  constructor() {
    super({});

    this.state = {
      defaultSelectValue: structkitVersion[0].value,
    };
  }

  async getData() {
    const main: any = this;

    main.props.getListOfProject("structkit", main.state.defaultSelectValue);
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    document.title = "Structkit JS document page";
  }

  render() {
    const main: any = this;
    const dropdownVersion = (event: any) => {
      console.log("dropdownVersion", this, ":", event.target.value);
      this.setState(
        {
          defaultSelectValue: event.target.value,
        },
        () => {
          main.getData();
        }
      );
      //main.props.getListOfProject(main.state.defaultSelectValue);
    };
    console.log("render", this);

    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody> 
            <div className="cards">
              <div className="cards-body">
                Working in progress
              </div>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {};
};
const mapDispathToProps = {};
const StructkitDocs = connect(mapStateToProps, mapDispathToProps)(Docs);
export default StructkitDocs;
