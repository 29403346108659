import StatesExtend from './states';
import Docs from './component/Doc';
import Home from './component/Home';
import Api from './component/Api';
import Page404 from 'app/component/Page404';
import {BrowserRouter as Router, Switch,Route} from "react-router-dom";

const BaseDomain ={
	SubDomain: "grasseum",
	SubRoute :()=>{
		return   (
	        <Router>
	          <Switch>
			  	<Route path="/api" component ={StatesExtend(Api)} />
			  	<Route path="/doc" component ={StatesExtend(Docs)} />
	            <Route exact path="/" component ={StatesExtend(Home)} />
				<Route path="*" component ={Page404} />
	          </Switch>
	        </Router>
	    )
	}	
}
export default BaseDomain;
