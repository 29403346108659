import {symphonyServer,laravelServer} from '../api';
import { templateValue } from 'structkit';

const getListOfProject = function(name: string,version:string){

    return async(dispath:any) =>{
        try{

            const  query =  `
            query {
                getProjectList(name: "<!- name !>",version: "<!- version !>") {
                    name
                    example
                    comment
                    return
                    arguments {
                        comment
                        name
                        type
                    }
                }
            }
          `;

          const templte = templateValue(query,{name,version});

          const api = await symphonyServer.post("/graphql",{data: {"query": templte }});
            
            dispath({
                type:"valid",
                data:api
            });
        }catch(e){
            dispath({
                type:"error",
                data:e
            });
        }   
    }
}

export {getListOfProject};
