import React,{Component} from "react";
import {imageLoad} from "../../../../utils/element";

class Home extends Component<{}> {
	
	componentWillMount(){}
	componentDidMount(){}
	render(){
		return (
			<> 
			<div className="align-text-center"> 
				<img src={imageLoad("codehyouka_404_page.png")} alt={"codehyouka_404_page"}/> 
			</div>
			</>
		);
	}
}
export default Home;
