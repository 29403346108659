import React from "react";

import { Link ,useHistory  } from "react-router-dom";
import {imageLoad,mainHost} from "../../utils/element";

function PageFooter(){
	const returnPage =`${mainHost()}/project`;
	const sitemapPage = `${mainHost()}/sitemap`;
	const contactPage = `${mainHost()}/contact`;
	return (
		<>
			<br/><br/>
			<footer>

				<div className="container cols-xs-12 align-center">
				<div className="rows">
					<div className="cols-md-1 cols-xs-12">
						<a href={returnPage}><img src={imageLoad("codehyouka-logo.svg")} width ="70px"alt={"codehyouka_under_construction_all_blue"}/></a>
					</div>
					<div className="cols-md-11 cols-xs-12 align-center">
					<b>Copyright @ 2024</b>
					</div>

					<ul
						className="list-group align-text-xs-center align-text-sm-center align-right"
					>
						<li className="list-items">
						<a href={sitemapPage}> Sitemap</a>
						</li>
						<li className="list-items">
						<a href={contactPage}> Contact us</a>
						</li>
						
					</ul>
				</div>
    			</div>
			</footer>
		</>
	)
}
export default PageFooter;
