
import {CodeEditorProps} from '../../../type/props';
import  {useEffect, useState} from "react";
import {importScipt} from 'getkit';
import {ifUndefined,isJson,range,parseString} from 'structkit';
import {jsLoad} from '../../../utils/element';
import {cleanJSFormat,cleanHTMLFormat,getReturnInJS} from '../../../utils/html_interpreter';

function CodeEditor(project:CodeEditorProps){

	const {code,jsfile,globalname} = project;
	const [scriptIn,setScriptIn] = useState(code)
	const [rowCount,setRowCount] = useState<number[]>(range(cleanJSFormat(scriptIn).split("\n").length))
	const [scriptOutput,setScriptOutput] = useState<string>("")
	const [isError,setIsError] = useState(false)
	

	useEffect(()=>{
		importScipt(jsLoad(jsfile??""));
	},[jsfile])
	  async function runScript(){
		
		setScriptOutput("Loading . . . ");
		try{ 
			  
			
				const func = new Function(globalname??"",getReturnInJS(cleanJSFormat(scriptIn)));
				setScriptOutput(func(ifUndefined(window,globalname,{__:"haha"})));
				const dataOutput:any = func(ifUndefined(window,globalname,{})) 
				const isVarJson = isJson(dataOutput);
					
					setIsError(false);
					if (isVarJson){
						setScriptOutput(parseString(dataOutput));
					}else{
						setScriptOutput(dataOutput.toString()+"");
					}
			 
						  
		}catch(E:any) {
			setIsError(true);
			setScriptOutput(E.toString());
		}
	}

	function keyContentEventDown(event:any) {
		event.spellcheck = false;
	
		if(event.keyCode == 9){
		
			document.execCommand('insertHTML', false, '&#009');
			
			return event.preventDefault()   
		}

	}
	function keyContentEventUp(event:any) {
		const rowData = cleanHTMLFormat(event.target.innerHTML);
		setScriptIn(rowData);

		const rowDataLen = rowData.split("\n").length
  //  console.log(rowDataLen,":rowDataLen");
    
	setRowCount(range(rowDataLen===1?1:rowDataLen,1));
	}
	//
    return <>	
				<br/>
				<div>
					<div ><button className = "align-right run-script button" onClick={runScript}>Run</button>
						<span className = "align-right">Test you code in your local browser &nbsp;</span><br/><br/>
						
					</div>
					
					<div className="code_editor">
						<div className="row_count">
      
								 {
									rowCount.map((value,key) =>{
										return (<>
											<div>{ value }</div>

										</>)
									})
								 }
							
						</div>
						<div className="row_editor" contentEditable="true" spellCheck="false" onKeyDown={e=>keyContentEventDown(e)} onKeyUp={e=>keyContentEventUp(e)} >
							{code}
						</div>

					</div>
					<div >
						{isError?<div className='content_invalid'>{scriptOutput}</div>:<div className='content_valid'>{scriptOutput}</div>}

					</div>
				</div>
				 
				
               
           
        </>
}
export default CodeEditor;
