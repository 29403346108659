import {httpInitialize} from 'getkit';
import {urlComposer} from 'url-assist';

const consUrl = urlComposer(window.location.origin);
consUrl.setSubdomain("apis")
const symphonyServer = httpInitialize({
    baseUrl: consUrl.getToString(),
    isJson: true,
  });

consUrl.setSubdomain("apil")
const laravelServer = httpInitialize({
    baseUrl: consUrl.getToString(),
    isJson: true,
  });
export {symphonyServer,laravelServer};
