import {ProjectVersion} from '../type/constant'

const structkitVersion:ProjectVersion[] =[
    {
        name:"1.4.842",
        value:"1_4_8_42"
    },
    {
        name:"1.4.8",
        value:"1_4_8"
    },
    {
        name:"1.4.7",
        value:"1_4_7"
    },
    {
        name:"1.4.61",
        value:"1_4_61"
    },
    {
        name:"1.4.1",
        value:"1_4_1"
    },
    {
        name:"1.3.1",
        value:"1_3_1"
    }
    
];

const getkitVersion:ProjectVersion[] =[
    {
        name:"0.5.0",
        value:"0_5_0"
    }
];

const urlassistVersion:ProjectVersion[] =[
    {
        name:"1.2.1",
        value:"1_2_1"
    },
    {
        name:"1.1.0",
        value:"1_1_0"
    },
    {
        name:"1.0.2",
        value:"1_0_2"
    },
    {
        name:"1.0.0",
        value:"1_0_0"
    }
];

const comptsVersion:ProjectVersion[] =[
    {
        name:"2.0.1",
        value:"2_0_1"
    }
];

export  {comptsVersion,getkitVersion, structkitVersion,urlassistVersion};

