import { Component } from "react";
import { connect } from "react-redux";

import HtmlScript from "app/component/HtmlScript";
import PageFooter from "app/component/PageFooter";

import TheBody from "app/component/design/TheBody";

import TheHead from "app/component/design/TheHead";

class Home extends Component<{}> {
  constructor() {
    super({});

    this.state = {};
  }
  componentDidMount() {
    document.title = "Structkit JS home page";
  }

  render() {
    console.log(this);

    return (
      <>
        <TheHead />
        <div className="container-lg align-center">
          <TheBody>
            <h3>Introduction</h3>
            <div className="cards">
              <div className="cards-body">
                Javascript library in using functional syntax for programmer.
              </div>
            </div>

            <h3>Why Structkit</h3>
            <div className="cards">
              <div className="cards-body">
                A Javascript library that make easier in working with arrays,
                number,object, String and etc.
              </div>
            </div>
            <h3>Download</h3>
            <div className="cards">
              <div className="cards-header">In a browser/HTML</div>
              <div className="cards-body">
                To download this JS library and install in your local directory.
                <br />
                <a href="https://raw.githubusercontent.com/compts/structkit/main/dist/web/structkit-full.iife.js">
                  Github raw js
                </a>
              </div>
            </div>
            <div className="cards">
              <div className="cards-header">NPM</div>
              <div className="cards-body">
                Using npm to install in your project folder
              </div>
              <code>npm i structkit</code>
            </div>

            <h3>How to import the Library</h3>
            <div className="cards">
              <div className="cards-header">In a browser/HTML</div>
              <div className="cards-body">
                To download this JS library and install in your local directory.
                <br />
                <a href="https://raw.githubusercontent.com/compts/structkit/main/dist/web/structkit-full.iife.js">
                  Github raw js
                </a>
              </div>
              <code>
                <HtmlScript src="structkit-full.iife.js" />
              </code>
            </div>
            <div className="cards">
              <div className="cards-header">Import on cjs</div>
              <div className="cards-body"></div>
              <code>const {"{repeat}"} = require('structkit');</code>
            </div>
            <div className="cards">
              <div className="cards-header">Import on ESM or TS</div>
              <div className="cards-body"></div>
              <code>import {"{repeat}"} from 'structkit';</code>
            </div>

            <h3>Example</h3>
            <div className="cards">
              <div className="cards-header">In a browser/HTML</div>
              <div className="cards-body">
                keep in mind <b>_stk</b> is used as global variable at html.
              </div>
              <code>
                _stk.repeat("1",20) <br />
                //11111111111111111111
              </code>
            </div>
            <div className="cards">
              <div className="cards-header">
                CJS, ESM and TS use this code below
              </div>
              <div className="cards-body">
                As you import the function above, you can use directly.
              </div>
              <code>
                repeat("1",20) <br />
                //11111111111111111111
              </code>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    StructkitHome: state.structkitReducer,
  };
};
const mapDispathToProps = {};
const StructkitHome = connect(mapStateToProps, mapDispathToProps)(Home);
export default StructkitHome;
