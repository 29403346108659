import {Component} from "react";
import TheBody from 'app/component/design/TheBody';
import TheHead from 'app/component/design/TheHead';
import PageFooter from 'app/component/PageFooter';


class Home extends Component<{}> {
	
	async getData(){

	}
	componentWillMount(){}
	componentDidMount(){
		document.title = "Dezainiwa home page"
	}
	render(){

		return (
			<>
			<TheHead/>
			<TheBody >
				<div className="container align-center">
					Working in progress	
				</div>
							
			</TheBody>	
			<PageFooter/>		
			</>
		)
	}
}
export default Home;
