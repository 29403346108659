import React from "react";
import { Link ,useHistory  } from "react-router-dom";

import {imageLoad} from "../../utils/element";
import {HeaderLinkProps} from '../../type/props';

function HeaderLink(props:HeaderLinkProps ){

	const {github, download} = props;
	return (
		<div className="header_menu">
				<Link to="/" >Home </Link> 
				<Link to="/doc" >Doc </Link> 
				<Link to="/api" > Api </Link> 
		</div>
	)
}
export default HeaderLink;
