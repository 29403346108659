
export const getListOfProjectReducer = (state:any,action:any)=>{ 

    if (action.type === "valid"){
        
        return action.data.data.data.getProjectList

    }
    return []
};
