import {domainDetails} from '../type/constant'
const projectDetails:domainDetails ={
	"grasseum":{
        github:"https://github.com/grasseum/grasseum",
        package:"https://www.npmjs.com/package/grasseum"
    },
    "compts":{
        github:"https://github.com/compts/compts-js",
        package:"https://www.npmjs.com/package/comptsjs"
    },
    "dezainiwa":{
        github:"https://github.com/dezainiwa/dezainiwa-web",
        package:""
    },
    "structkit":{
        github:"https://github.com/compts/structkit",
        package:"https://www.npmjs.com/package/structkit"
    },
    "getkit":{
        github:"https://github.com/compts/getkit",
        package:"https://www.npmjs.com/package/getkit"
    },
    "fornetserve":{
        github:"https://github.com/grasseum/fornet_serve",
        package:""
    },
    "castanett":{
        github:"https://github.com/fonipts/pluton-kit",
        package:""
    },
    "cloverpage":{
        github:"https://github.com/fonipts/cloverpage",
        package:""
    },
    "plutonkit":{
        github:"https://github.com/fonipts/pluton-kit",
        package:"https://pypi.org/project/plutonkit"
    },
    "urlassist":{
        github:"https://github.com/compts/url-assist",
        package:"https://www.npmjs.com/package/url-assist"
    }
}


const BaseDomain ={
	
}
export {BaseDomain, projectDetails};
