import React, { Component, useRef } from "react";
import { structkitVersion } from "../../../../constants/projectVersion";
import ApiBody from "app/component/design/ApiBody";
import ApiBodyMenu from "app/component/design/ApiBodyMenu";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

class Api extends Component<{}> {
  constructor(props: any) {
    super(props);

    this.state = {
      defaultSelectValue: structkitVersion[0].value,
      myRef: React.createRef(),
    };
  }

  async getData() {
    const main: any = this;

    main.props.getListOfProject("structkit", main.state.defaultSelectValue);
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    document.title = "Structkit JS document page";
  }

  render() {
    const main: any = this;

    console.log(structkitVersion, ":structkitVersion");
    const dropdownVersion = (event: any) => {
      console.log("dropdownVersion", this, ":", event.target.value);
      this.setState(
        {
          defaultSelectValue: event.target.value,
        },
        () => {
          main.getData();
        }
      );
    };

    return (
      <>
        <TheHead>
          <div className="select_version_ui">
            <select className="dropdown" onChange={dropdownVersion}>
              {structkitVersion.map((opt) => (
                <option value={opt.value}>{opt.name}</option>
              ))}
            </select>
          </div>
          <ApiBodyMenu projects={main.props.getListOfProjectReduce} jsfile="structkit.js" globalname="_stk" />
        </TheHead>
        <TheBody isWithSideBody={true}>
          <ApiBody projects={main.props.getListOfProjectReduce} isCodeRun={true} jsfile="structkit.js" globalname="_stk" />
        </TheBody>

        <PageFooter />
      </>
    );
  }
}
export default Api;
