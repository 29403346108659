import { Component } from "react";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

class Home extends Component<{}> {
  async getData() {}
  componentWillMount() {}
  componentDidMount() {
    document.title = "Grasseum JS home page";
  }
  render() {
    return (
      <>
        <TheHead />
        <TheBody>
          <div className="container-lg align-center">
            <img
              src="image/project/grasseum_landing_img.gif"
              alt={"grasseum_landing_img"}
              width="100%"
            />
          </div>
          <div className="container-lg align-center">
            <h4>A toolkit for script automation</h4>
            <h3>Installation</h3>
            <div className="cards">
              <div className="cards-header">NodeJs</div>
              <code>npm install --global grasseum</code>
            </div>
          </div>
          <div className="container-lg align-center">
            <div className="cols-md-4 container-inline">
              <div className="cards">
                <div className="cards-header">Script sample</div>

                <code>
                  exports.module=function(grassconf){"{"}
                  <br />
                  <br />
                  grassconf.load("test1",function(){"{"}
                  <br />
                  <br />
                  return grassconf.src(["src/*"])
                  <br />
                  .pipe(grassconf.dest("dest/" )); <br />
                  <br />
                  {"}"}) <br />
                  {"}"} <br />
                  <br />
                  exports.execute=function( lib ){"{"} <br />
                  <br />
                  lib.default=function(strm){"{"}
                  <br />
                  strm.series("test1") <br />
                  {"}"}
                  <br />
                  lib.thread=function(strm){"{"}
                  <br />
                  strm.watch(["src/*"],["test1"]) <br />
                  {"}"}
                  <br />
                  return lib;
                  <br />
                  {"}"} <br />
                </code>
                <div className="cards-body">
                  Create a <b>grassfile.js</b> copy and paste this code
                </div>
              </div>
            </div>
            <div className="cols-md-4 container-inline">
              <div className="cards">
                <div className="cards-header">
                  Execute script on your Terminal
                </div>
                <code>grasseum script</code>
                <div className="cards-body"></div>
              </div>
            </div>
            <div className="cols-md-4 container-inline">
              <div className="cards">
                <div className="cards-header">Message in terminal</div>
                <code>
                  Executing grassfile.js . . . <br />
                  <br />
                  &nbsp;&nbsp;Stream name `test1` starting !<br />
                  <br />
                  &nbsp;&nbsp;Stream name `test1` completed2 !<br />
                  <br />
                  Complete executing the whole project
                  <br />
                  <br />
                </code>
                <div className="cards-body"></div>
              </div>
            </div>
          </div>
        </TheBody>
        <PageFooter />
      </>
    );
  }
}
export default Home;
