import {Component} from "react";
import TheBody from 'app/component/design/TheBody';
import TheHead from 'app/component/design/TheHead';
import PageFooter from 'app/component/PageFooter';


class Home extends Component<{}> {
	
	async getData(){
	}
	componentWillMount(){}
	componentDidMount(){
		document.title = "Fornetserver JS home page"
	}
	render(){
		return (
			<>
			<TheHead/>
			<TheBody>
			<div className="container-lg align-center"> 
				<h4>HTTP server framework for faster deployment</h4>
				<h3>Installation</h3>
				<div className="cards">
				<div className="cards-header">NodeJs</div>
					<code>
						npm i fornetserve
					</code>
				</div>

				<h3>Code</h3>
				<div className="cards">
				<div className="cards-header">NodeJs</div>
					<code>
						import {`{appServer}, {http}`} from 'fornetserve';<br/><br/>
						const apps = appServer();<br/><br/>
						{`apps.get("/", (req, res) => {`} <br/>
							&nbsp;{`res.status(200);`}<br/>
							&nbsp;{`res.content("Yahooo");`}<br/>
							{`});`}
					</code>
				</div>
			</div>
			</TheBody>
			<PageFooter/>
			</>
		)
	}
}
export default Home;
