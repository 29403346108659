import {isEmpty,filter,stringUnEscape} from 'structkit';

export const cleanHTMLFormat =(src:string):string=>{
    src = src.replaceAll(/\<br\>/g,"\n")
    src = src.replaceAll(/\<(div|\/div)\>/g,"\n")
    src = src.replaceAll(/\<(.*?)\>/g,"")
    return stringUnEscape(src);
  
}


export const cleanJSFormat =(src:string):string=>{
    src = src.replaceAll(/[\\]/g,"")
    src = src.replaceAll(/[\;]/g,";\n")
    

    let rowStr:any = src.split("\n");

    rowStr = filter(rowStr,(key:any,val:any)=>!isEmpty(val));
    src = rowStr.join("\n");
    src = src.trim().replaceAll(/[\/]{1,}$/g,"")
    return src;

}

export const getReturnInJS = (content:string):string =>{
    let rowStr:any = content.split("\n");
    if (!/^(return)\b/g.test(rowStr[rowStr.length-1])) {
        rowStr[rowStr.length-1] = "return "+rowStr[rowStr.length-1]
      }
    return rowStr.join("\n");  
}