import React,{Component} from "react";
//import PageHeader from 'app/component/PageHeader';
import PageFooter from 'app/component/PageFooter';
import {imageLoad} from "../../utils/element";
 

import PageUnderMaintenance from 'app/component/PageUnderMaintenance';

import HeaderDomain from './PageHeader';

class Page404 extends Component<{}> {
	
	componentWillMount(){}
	componentDidMount(){}
	render(){
		return (
			<>
			<HeaderDomain/>
			<div className="align-text-center">
				Page not found
				<img src={imageLoad("codehyouka_404_page.png")} alt={"codehyouka_404_page"}/> 

			</div>
			<PageFooter/>
			</>
		)
	}
}
export default Page404;
