import {DocComponentProps} from '../../../type/props';
import {CodeExecRef} from '../../../type/constant'
import {has,clone, stringUnEscape} from 'structkit';
import { useState,useRef,useEffect } from "react";
import CodeEditor from "./CodeEditor";
import {cleanJSFormat} from '../../../utils/html_interpreter';

function ApiBody(project:DocComponentProps){

	const [codeExec, setCodeExec] = useState<CodeExecRef>({});
	const {projects,table,isCodeRun,jsfile,globalname} = project;


	function runScriptInRow(id:number,code:string) {
		const cloneCodeExec = clone(codeExec)
		cloneCodeExec[id] = {
			code:code
		}
		

		setCodeExec(cloneCodeExec);
	}

	return (
		<> 
		 	<div > 
			
				{projects.length===0?<>
				<h3>Page is loading</h3>
				</>:<></>}

			 {
			  projects.map((option,key) =>(
				<div className="cards" id={option.name}>
					<div className="cards-header">{option.name}</div>
					<b>Comment:</b> {option.comment}
					<div className="cards-body-argument">
					<b>Arguments:</b> <br/>
					<ul>
					{
					option.arguments?option.arguments.map(option1 =>(
						<>
							<li>
								Name: {option1.name} <br/>
								Type: {option1.type} <br/>
								Comment: {option1.comment} <br/>
							</li>
						</>
					)):(<></>)
					}
					{
						table?table.map((option2)=>{

							if(option2.name === option.name) {

								return <>
								<h4>
									Available method you can use
								</h4>
								<table className='table'>
									<thead>
									{
										 option2.thead.map(option3 =>(
											<th>{option3}</th>
										 ))
									}
									</thead>
									<tbody>
									{
										 option2.tbody.map(option3 =>(
											<tr>{option3.value.map(option4=>(
												<td>
													{
														option4
													}
												</td>	
											))}
											</tr>
										 ))
									}
									</tbody>
									
									
								</table>
								</>
							}
							return <></>
						} 
						):(<></>)
					}
					</ul>
					</div>
					<code>
						
						{stringUnEscape(option.example).split("=>")[0]}
						<br/> {"=>"}<br/>
						<div>{stringUnEscape(option.example).split("=>")[1]}</div>
					</code>
					{isCodeRun?<>
						<div className='align-right'>
							{codeExec[key]?<>
								<CodeEditor code={cleanJSFormat((globalname===""?"":globalname+".")+codeExec[key].code)} jsfile={jsfile} globalname={globalname}></CodeEditor>
								</>:<><br/>
								<button className = "align-right button" onClick={e=>{runScriptInRow(key, stringUnEscape(option.example).split("=>")[0])}}> Run this code in your browser </button>
								</>}
								
						</div>
						
						</>:<></>}
				</div>
			  ))
		  	}
			</div>
	  </>
  
		);
}
export default ApiBody;
