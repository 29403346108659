import React from "react";
import {HtmlScriptProps} from '../../type/props';

function HtmlScript(props:HtmlScriptProps ){

	const {src} = props;
	return (
		<>
			<span>
				{'<'}
			</span>
			<span>
				script src="{src}"
			</span>
			<span>
				{'>'}
			</span>
			<span>
				{'</'}
			</span>
			<span>
				script
			</span>
			<span>
				{'>'}
			</span>
		</>
	)
}
export default HtmlScript;
