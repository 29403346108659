import { Component } from "react";
import HtmlScript from "app/component/HtmlScript";
import TheBody from "app/component/design/TheBody";
import TheHead from "app/component/design/TheHead";
import PageFooter from "app/component/PageFooter";

class Home extends Component<{}> {
  async getData() {}
  componentWillMount() {}
  componentDidMount() {
    document.title = "Compts JS home page";
  }
  render() {
    return (
      <>
        <TheHead />
        <div className="container align-center">
          <TheBody>
            <h3>Introduction</h3>
            <div className="cards">
              <div className="cards-body">
                ComptsJS a lightweight library that simplifies <br />
                programming with JavaScript in web application.
              </div>
            </div>
            <h3>Download</h3>
            <div className="cards">
              <div className="cards-header">In a browser/HTML</div>
              <div className="cards-body">
                To download this JS library and install in your local directory.
                <br />
                <a href="https://github.com/compts/compts-js/tree/master/dist/web/compts-full.js">
                  Github raw js(ComptsJS)
                </a>
                <br />
                <a href="https://raw.githubusercontent.com/compts/structkit/main/dist/web/structkit-full.iife.js">
                  Github raw js(StructkitJS) your dependency library
                </a>
              </div>
            </div>
            <div className="cards">
              <div className="cards-header">NPM</div>
              <div className="cards-body">
                Using npm to install in your project folder
              </div>
              <code>npm i comptsjs</code>
            </div>

            <h3>Example</h3>
            <div className="cards">
              <div className="cards-header">In a browser/HTML</div>
              <div className="cards-body"></div>
              <code>dom("body").html("Hello word")</code>
            </div>
            <div className="cards">
              <div className="cards-header">Import on cjs</div>
              <div className="cards-body"></div>
              <code>
                const {"{dom}"} = require('comptsjs');
                <br />
                dom("body").html("Hello word")
              </code>
            </div>
            <div className="cards">
              <div className="cards-header">Import on ESM or TS</div>
              <div className="cards-body"></div>
              <code>
                import {"{dom}"} from 'comptsjs';
                <br />
                dom("body").html("Hello word")
              </code>
            </div>
          </TheBody>
        </div>
        <PageFooter />
      </>
    );
  }
}
export default Home;
