import {Component} from "react";
import {comptsVersion} from '../../../../constants/projectVersion';
import TheBody from 'app/component/design/TheBody';
import TheHead from 'app/component/design/TheHead';
import PageFooter from 'app/component/PageFooter';
import ApiBody from 'app/component/design/ApiBody';
import ApiBodyMenu from 'app/component/design/ApiBodyMenu';


class Api extends Component<{}> {
	
	constructor(){
		super({});
		
		this.state ={
			defaultSelectValue:comptsVersion[0].value
		}
	}

	async getData(){
		const main: any = this;

		main.props.getListOfProject("compts",main.state.defaultSelectValue);

	}
	componentWillMount(){

		this.getData();
	}
	componentDidMount(){
		document.title = "Compts JS document page"
	}

	
	render(){
		const main: any = this;
		const dropdownVersion=(event:any) =>{
			
			this.setState({
				defaultSelectValue:event.target.value
			},()=>{
				main.getData();
			});

		}

		return (
			<>
			<TheHead>
				<div className="select_version_ui">
					<select className = "dropdown" onChange={dropdownVersion}>
						{ comptsVersion.map(opt => <option value={opt.value}>{opt.name}</option>)}
					</select>									
				</div>
				<ApiBodyMenu projects={main.props.getListOfProjectReduce} jsfile="compts.js" globalname=""/>
			</TheHead>
			<TheBody isWithSideBody={true}> 
				
				<ApiBody projects={main.props.getListOfProjectReduce}  isCodeRun={true} jsfile="compts.js" globalname=""/>
 
			</TheBody>		
			<PageFooter/>	
			</>
		)
	}
}
export default Api;
